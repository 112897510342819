import { PAGE_TYPE, ROUTES } from '../../../../common/constants';
import {
  getVideoProgressInPercentage,
  secondsToMinute
} from '../../../../common/utils';
import BlurHashImage from '../../../BlurHashImage';
import CustomAnchor from '../../../CustomAnchor';
import ArrowProgress from '../../../imageComponent/ArrowProgress';
import CollectionDetailLoading from '../../../skeleton/CollectionDetailLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';

function CollectionDetail({
  title,
  description,
  blurhash,
  img: thumbnailImage,
  collectionItemData,
  videosWatchTimes,
  podcastListenTimes
}) {
  return (
    <section className="collection-items-section">
      <div className="collection-item-container">
        <SectionHeader heading={title} headerDescription={description} />
        <div className="cover-img">
          <BlurHashImage
            src={thumbnailImage}
            blurhash={blurhash}
            fill
            alt={title}
            className="cover"
          />
        </div>

        <div className="collection-items-details">
          <div className="collection-item-list-wrapper">
            {collectionItemData?.map((item, index) => (
              <CustomAnchor
                href={
                  item?.type === PAGE_TYPE.VIDEO
                    ? `${ROUTES.VIDEOS}/${item?.itemData?.slug}`
                    : `${ROUTES.PODCASTS}/${item?.itemData?.slug}`
                }
                key={item?.id}
              >
                <div
                  key={item?.id}
                  className={
                    index === 0
                      ? 'active collection-item-list'
                      : 'collection-item-list'
                  }
                >
                  <div className="d-flex gap-8">
                    <div className="text-small text-black-90">
                      {item?.sequence}
                    </div>
                    <div className="time-stemp text-small text-black-90">
                      {item?.type === PAGE_TYPE.VIDEO
                        ? secondsToMinute(
                            item?.itemData?.videoAsset?.information
                              ?.durationInSeconds
                          )
                        : secondsToMinute(
                            item?.itemData?.audio?.information
                              ?.durationInSeconds
                          )}
                    </div>
                  </div>
                  <div className="episode-description-wrapper">
                    <div className="episode-description">
                      <div className="text-md text-black-90 title">
                        <strong>{item?.itemData?.title}</strong>
                      </div>
                      <p className="text-small text-black-80">
                        {item?.itemData?.description}
                      </p>
                    </div>
                    <ArrowProgress
                      playValue={
                        item?.type === PAGE_TYPE.VIDEO
                          ? getVideoProgressInPercentage(
                              videosWatchTimes?.[item?.itemData?.id],
                              item?.itemData?.videoAsset?.information
                                ?.durationInSeconds
                            )
                          : getVideoProgressInPercentage(
                              podcastListenTimes?.[item?.itemData?.id],
                              item?.itemData?.audio?.information
                                ?.durationInSeconds
                            )
                      }
                    />
                  </div>
                </div>
              </CustomAnchor>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

CollectionDetail.Loading = function Loading() {
  return <CollectionDetailLoading />;
};

export default CollectionDetail;
