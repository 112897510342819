import { useMatomo } from '@datapunt/matomo-tracker-react';
import dynamic from 'next/dynamic';
import {
  BUTTON_ALIGNMENT_CLASS,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TEXT_ALIGNMENTS_CLASS,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import ActionButton from '../../../ActionButton';
import BlurHashImage from '../../../BlurHashImage';
import HeroBlockLoading from '../../../skeleton/HeroBlockLoading';

const ReactPlayer = dynamic(() => import('react-player/lazy'), {
  loading: () => null,
  ssr: false
});

function HeroBlock({
  imageSrc,
  blurhash,
  title,
  description,
  date,
  location,
  primaryAction,
  secondaryAction,
  videoSrc,
  textAlignment = 'LEFT'
}) {
  const {
    state: { currentUser }
  } = useApp();

  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        title,
        email: currentUser?.email ?? ''
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        title,
        userId: currentUser?.id ?? ''
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <section
      className={`relative heroblock bg-n-1000 ${TEXT_ALIGNMENTS_CLASS[textAlignment]}`}
    >
      {imageSrc ? (
        <BlurHashImage
          src={imageSrc}
          blurhash={blurhash}
          fill
          alt={title}
          withBlur={false}
          priority
          className="cover"
        />
      ) : null}
      {videoSrc ? (
        <ReactPlayer
          url={videoSrc}
          controls={false}
          playing
          playsinline
          loop
          muted
          width="100%"
          height="100%"
        />
      ) : null}

      <div className="container">
        <div className="herocontent relative">
          {title ? (
            <pre className="text-white-90 title mb-16">{title}</pre>
          ) : null}
          {description ? (
            <pre className="description text-white-90 mb-16">{description}</pre>
          ) : null}
          {date ? (
            <div className="date-heading text-white-90">{date}</div>
          ) : null}
          {location ? (
            <div className="date-heading text-white-90">{location}</div>
          ) : null}
          {primaryAction || secondaryAction ? (
            <div
              className={`hero-button-wrapper ${BUTTON_ALIGNMENT_CLASS[textAlignment]}`}
            >
              {secondaryAction?.title ? (
                <ActionButton
                  secondaryBtn
                  size="large"
                  type={secondaryAction?.type}
                  internalPageType={secondaryAction?.internalPageType}
                  url={secondaryAction?.url}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_HERO_SECONDARY_BTN_CLICKED
                    )
                  }
                  icon={secondaryAction?.asset}
                >
                  {secondaryAction?.title}
                </ActionButton>
              ) : null}
              {primaryAction?.title ? (
                <ActionButton
                  primaryBtn
                  size="large"
                  type={primaryAction?.type}
                  internalPageType={primaryAction?.internalPageType}
                  url={primaryAction?.url}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_HERO_PRIMARY_BTN_CLICKED
                    )
                  }
                  icon={primaryAction?.asset}
                >
                  {primaryAction?.title}
                </ActionButton>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

HeroBlock.Loading = function Loading() {
  return <HeroBlockLoading />;
};

export default HeroBlock;
