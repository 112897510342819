import TagGridLoading from '../../../skeleton/TagGridLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import TagCard from './TagCard';

function TagGrid({
  className,
  sectionHeading,
  headerLink,
  headerDescription,
  icon,
  data = []
}) {
  return (
    <div className="tag_grid">
      <SectionHeader
        heading={sectionHeading}
        headerLink={headerLink}
        headerDescription={headerDescription}
      />

      {data?.map((item, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${item?.id}${i}`}>
            <TagCard
              className={className}
              icon={icon}
              id={item?.id}
              name={item?.name}
              href={item?.href}
            />
          </div>
        );
      })}
    </div>
  );
}

TagGrid.Loading = function Loading() {
  return <TagGridLoading />;
};

export default TagGrid;
