import { Col, Row } from 'antd';
import React from 'react';

function CategoryGridLoading() {
  const CategorySliderData = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <section className="category-grid skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 16 },
            { xs: 8, sm: 8, md: 16, lg: 16 }
          ]}
        >
          {CategorySliderData.map((item) => (
            <Col
              key={item?.id}
              xs={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <div className="category-card category-grid skeleton" />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default CategoryGridLoading;
