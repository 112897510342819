import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Typography } from 'antd';
import dynamic from 'next/dynamic';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';

const BlurHashImage = dynamic(() => import('../../../BlurHashImage'), {
  loading: () => null,
  ssr: false
});

const { Text, Paragraph } = Typography;
function CollectionCard({
  collectionMd,
  collectionLg,
  title,
  description,
  collectionList,
  videoCount,
  image,
  blurhash,
  href = '#'
}) {
  const {
    state: { placeholderImage, currentUser }
  } = useApp();

  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        title,
        videoCount
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        title,
        videoCount
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };
  return (
    <div
      className={`collection-card ${collectionMd ? 'collection-md' : ''} ${
        collectionLg ? 'collection-lg' : ''
      } ${collectionList ? 'collection-list' : ''}`}
      onClick={() =>
        handleAnalytics(
          MATOMO_CATEGORY.VIEW_DETAIL,
          MATOMO_ACTION.VIEW_COLLECTION
        )
      }
    >
      <CustomAnchor href={href} className="collection-img relative">
        <BlurHashImage
          src={image || placeholderImage}
          blurhash={blurhash}
          fill
          alt={title}
          className="cover"
        />
      </CustomAnchor>
      <div className="collection-dec">
        {title ? <Text className="mb-4 block title">{title}</Text> : null}
        {videoCount !== undefined ? (
          <Text className="text-small text-n-800 mb-4 block video-count">
            {videoCount} Items
          </Text>
        ) : null}

        {collectionList ? (
          <Paragraph className="text-small text-black-80 collection-description">
            {description}
          </Paragraph>
        ) : (
          <Text className="text-tiny text-black-80 block collection-description">
            {description}
          </Text>
        )}
      </div>
    </div>
  );
}
export default CollectionCard;
