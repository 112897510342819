import React from 'react';

function FeaturedCollectionLoading() {
  return (
    <section className="featured-collection skeleton-loader">
      <div className="container-rnp">
        <div className="featured-collection-wrapper bg-n-700">
          <div className="featured-collection-img skeleton" />
          <div className="featured-collection-dec">
            <div className="title skeleton" />
            <div className="featured-collection-video-count skeleton" />
            <p className="skeleton" />
            <p className="skeleton" />
            <p className="skeleton" />
            <div className="button skeleton" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturedCollectionLoading;
