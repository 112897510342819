import { Carousel } from 'antd';
import React from 'react';

function CategorySliderLoading({ settings }) {
  const CategorySliderData = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <section className="category-slider skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
      </div>
      <div className="one-side-carousel ">
        <Carousel {...settings}>
          {CategorySliderData.map((item) => (
            <div key={item} className="category-card skeleton" />
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default CategorySliderLoading;
