import { Carousel } from 'antd';
import CollectionPillCarouselLoading from '../../../skeleton/CollectionPillCarouselLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CollectionPillCard from './CollectionPillCard';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  variableWidth: true,
  draggable: true,
  loop: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

function CollectionPillCarousel({
  headerLink,
  data = [],
  sectionHeading,
  headerDescription
}) {
  return (
    <div className="tag-carousel">
      <SectionHeader
        headerLink={headerLink}
        heading={sectionHeading}
        headerDescription={headerDescription}
      />
      <Carousel {...settings}>
        {data?.map((item) => {
          return (
            <div key={item?.id}>
              <CollectionPillCard
                name={item?.title}
                href={item?.href}
                heading={sectionHeading}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

CollectionPillCarousel.Loading = function Loading() {
  return <CollectionPillCarouselLoading settings={settings} />;
};
export default CollectionPillCarousel;
