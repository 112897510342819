import nth from 'lodash/nth';
import React from 'react';

function Chapters(props) {
  const { chapters, handleChapter, time } = props;
  return (
    <div>
      {nth(chapters, 0)?.cues?.length > 0 && (
        <div className="chapters-wrapper">
          {Object?.entries(nth(chapters, 0)?.cues)?.map((item) => {
            return (
              <div
                className={`${
                  time?.toFixed(0) >= nth(item, 1)?.startTime &&
                  time?.toFixed(0) < nth(item, 1)?.endTime
                    ? 'chapters-item active-chapters'
                    : 'chapters-item'
                }`}
                key={nth(item, 1)?.id}
                onClick={() => handleChapter(nth(item, 1))}
              >
                <span key={nth(item, 1)?.id}>{nth(item, 1)?.text}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Chapters;
