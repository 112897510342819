import { Carousel } from 'antd';
import { filter } from 'lodash';
import React from 'react';
import FormCarouselLoading from '../../../skeleton/FormCarouselLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import FormCard from './FormCard';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 5,
  variableWidth: true,
  draggable: true,
  loop: true,
  scrollOverflow: true,
  smooth: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1550,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
function FormCarousel({
  sectionHeading,
  headerDescription,
  data = [],
  showCta,
  instanceData,
  instanceId,
  instanceType
}) {
  return (
    <section className="form-card-slider">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item?.id}>
                <FormCard
                  title={item?.title}
                  cta={filter(item?.cta, (cta) => cta?.type === 'PRIMARY')}
                  href={item?.href}
                  submitCount={item?.submitCount ?? 0}
                  type={item?.type}
                  id={item?.id}
                  showCta={showCta}
                  instanceData={instanceData}
                  instanceId={instanceId}
                  instanceType={instanceType}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}

FormCarousel.Loading = function Loading() {
  return <FormCarouselLoading settings={settings} />;
};
export default FormCarousel;
