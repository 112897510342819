import SectionHeader from '../../SectionHeader/SectionHeader';

function HtmlTextForm({ data = [], sectionHeading, headerDescription }) {
  return (
    <div className="cms-pages">
      <SectionHeader
        heading={sectionHeading}
        headerDescription={headerDescription}
      />
      <div className="cms-content">
        <div
          className="m-0 paragraph-m text-black-90"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data
          }}
        />
      </div>
    </div>
  );
}
export default HtmlTextForm;
