import { Col, Collapse, Row } from 'antd';
import { filter, map } from 'lodash';
import { CaretDown, CaretUp } from 'phosphor-react';
import FaqModuleLoading from '../../../skeleton/FaqModuleLoading';

function FaqModule({ title, subTitle, data = [] }) {
  return (
    <section className="faq-section">
      <div className="container">
        <div className="container-marketing">
          <div className="faq-section-title mb-32">
            {title && <div className="text-white-90 title">{title}</div>}
            {subTitle && <p className="text-white-80 subtitle">{subTitle}</p>}
          </div>
          <div className="cs-accordion">
            <Row gutter={[{ md: 32, xs: 0 }, 0]}>
              <Col md={{ span: 24 }} lg={{ span: 12 }}>
                <Collapse
                  accordion
                  expandIconPosition="end"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  expandIcon={({ isActive }) =>
                    isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
                  }
                  items={map(
                    filter(data, (_, index) => index % 2 === 0),
                    (item) => {
                      return {
                        key: item?.id,
                        label: item?.heading,
                        children: (
                          <div
                            className="m-0 paragraph-m"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: item?.cmsContent
                            }}
                          />
                        )
                      };
                    }
                  )}
                />
              </Col>
              <Col md={{ span: 24 }} lg={{ span: 12 }}>
                <Collapse
                  accordion
                  expandIconPosition="end"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  expandIcon={({ isActive }) =>
                    isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
                  }
                  items={map(
                    filter(data, (_, index) => index % 2 !== 0),
                    (item) => {
                      return {
                        key: item?.id,
                        label: item?.heading,
                        children: (
                          <div
                            className="m-0 paragraph-m"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: item?.cmsContent
                            }}
                          />
                        )
                      };
                    }
                  )}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

FaqModule.Loading = function Loading() {
  return <FaqModuleLoading />;
};

export default FaqModule;
