import CollectionListLoading from '../../../skeleton/CollectionListLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CollectionCard from './CollectionCard';

function CollectionList({
  data = [],
  sectionHeading,
  headerLink,
  headerDescription,
  separator,
  collectionMd,
  collectionLg
}) {
  return (
    <section
      className={`collection-list-section ${
        !separator ? ' separator-off' : ''
      }`}
    >
      <div className="container-rnp">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        {data?.map((item) => {
          return (
            <div key={item?.id} className=" collection-list-item">
              <CollectionCard
                href={item?.href}
                collectionList
                videoCount={item?.videoCount}
                title={item?.title}
                description={item?.description}
                image={item?.coverImg}
                blurhash={item?.blurhash}
                collectionMd={collectionMd}
                collectionLg={collectionLg}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}

CollectionList.Loading = function Loading() {
  return <CollectionListLoading />;
};

export default CollectionList;
