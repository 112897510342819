import React from 'react';

function FormDisplayLoading() {
  return (
    <section className="donate-page inner-page skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
      </div>
      <div className="container-rnp">
        <div className="donate-block">
          <div className="donate-amount">
            <div className="form-title skeleton" />
            <div className="ant-typography mb-16 skeleton" />
          </div>
          <div className="donate-form">
            <div className="form-group">
              <div className="label skeleton" />
              <div className="input skeleton" />
            </div>
            <div className="form-group">
              <div className="label skeleton" />
              <div className="input skeleton" />
            </div>
            <div className="button skeleton" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormDisplayLoading;
