import React from 'react';

function TagGridLoading() {
  const TagCarouselData = [1, 2, 3, 4, 5];
  return (
    <div className="tag_grid skeleton-loader">
      <div className="section-header skeleton-loader">
        <div className="sh-top mb-4">
          <div className="sh-title skeleton" />
          <span className="flex-1" />
          <div className="d-flex align-center text-n-900 button-m skeleton" />
        </div>
      </div>
      {TagCarouselData.map((item) => (
        <div className="ant-tag mr-4 skeleton" key={item?.id} />
      ))}
    </div>
  );
}

export default TagGridLoading;
