import { Carousel } from 'antd';
import React from 'react';

function CollectionPillCarouselLoading({ settings }) {
  const pillData = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="tag-carousel skeleton-loader">
      <div className="section-header skeleton-loader">
        <div className="sh-top mb-4">
          <div className="sh-title skeleton" />
          <span className="flex-1" />
          <div className="d-flex align-center text-n-900 button-m skeleton" />
        </div>
      </div>
      <Carousel {...settings}>
        {pillData.map((item) => (
          <div className="ant-tag skeleton mr-8" key={item?.id} />
        ))}
      </Carousel>
    </div>
  );
}

export default CollectionPillCarouselLoading;
