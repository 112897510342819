import { Col, Row } from 'antd';
import React from 'react';

function PodcastListLoading() {
  const videoData = [1, 2, 3, 4, 5];
  return (
    <section className="video-list-grid  skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
      </div>
      <div className="container-rnp">
        <Row>
          {videoData.map((item) => (
            <Col
              key={item?.id}
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="video-card video-list" key={item}>
                <div className="video-image skeleton" />
                <div className="vc-description">
                  <div className="part skeleton" />
                  <div className="category skeleton" />
                  <div className="paragraph skeleton" />
                  <div className="paragraph skeleton" />
                  <div className="paragraph skeleton" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default PodcastListLoading;
