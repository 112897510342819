// eslint-disable-next-line import/prefer-default-export
export function FacebookShare() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#1877F2" />
      <path
        d="M33.3422 30.9375L34.4062 24H27.75V19.5C27.75 17.6016 28.6781 15.75 31.6594 15.75H34.6875V9.84375C34.6875 9.84375 31.9406 9.375 29.3156 9.375C23.8313 9.375 20.25 12.6984 20.25 18.7125V24H14.1562V30.9375H20.25V47.7094C21.4734 47.9016 22.725 48 24 48C25.275 48 26.5266 47.9016 27.75 47.7094V30.9375H33.3422Z"
        fill="white"
      />
    </svg>
  );
}
export function TwitterShare() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 30.127C60 13.5584 46.5685 0.126953 30 0.126953C13.4315 0.126953 0 13.5584 0 30.127C0 46.6955 13.4315 60.127 30 60.127C46.5685 60.127 60 46.6955 60 30.127Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.125 14.8145H23.75L31.25 24.6582L39.6875 14.8145H45L33.5938 27.7832L46.875 45.4395H36.5625L28.4375 34.8145L19.0625 45.4395H13.75L25.9375 31.5332L13.125 14.8145ZM19.375 17.9395H22.3438L40.7812 42.3145H37.9688L19.375 17.9395Z"
        fill="white"
      />
    </svg>
  );
}
export function TelegramShare() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.799805" width="48" height="48" rx="24" fill="#0088cc" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6638 23.7469C18.6603 20.6986 23.3258 18.689 25.6601 17.7181C32.3252 14.9459 33.7101 14.4643 34.6128 14.4484C34.8113 14.4449 35.2552 14.4941 35.5428 14.7274C35.7856 14.9245 35.8524 15.1906 35.8844 15.3774C35.9163 15.5642 35.9561 15.9897 35.9245 16.3222C35.5633 20.1172 34.0005 29.3265 33.2054 33.577C32.869 35.3755 32.2065 35.9785 31.5652 36.0375C30.1715 36.1658 29.1132 35.1164 27.7633 34.2316C25.651 32.8469 24.4576 31.985 22.4073 30.6338C20.0377 29.0723 21.5738 28.2141 22.9242 26.8115C23.2776 26.4445 29.4184 20.8589 29.5373 20.3523C29.5521 20.2889 29.5659 20.0527 29.4256 19.9279C29.2853 19.8032 29.0781 19.8459 28.9287 19.8798C28.7168 19.9279 25.3424 22.1582 18.8053 26.5709C17.8475 27.2286 16.9799 27.5491 16.2026 27.5323C15.3457 27.5138 13.6973 27.0478 12.4719 26.6495C10.9689 26.1609 9.77437 25.9026 9.87838 25.0729C9.93256 24.6407 10.5277 24.1987 11.6638 23.7469Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint1_linear_1423_56145"
          x1="24.7998"
          y1="0"
          x2="24.7998"
          y2="47.644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2AABEE" />
          <stop offset="1" stop-color="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export function LinkedinShare() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.200195" width="48" height="48" rx="24" fill="#0A66C2" />
      <path
        d="M35.4512 10.7998H12.9488C12.432 10.7998 11.9363 11.0051 11.5708 11.3706C11.2053 11.7361 11 12.2318 11 12.7486V35.251C11 35.7678 11.2053 36.2635 11.5708 36.629C11.9363 36.9945 12.432 37.1998 12.9488 37.1998H35.4512C35.968 37.1998 36.4637 36.9945 36.8292 36.629C37.1947 36.2635 37.4 35.7678 37.4 35.251V12.7486C37.4 12.2318 37.1947 11.7361 36.8292 11.3706C36.4637 11.0051 35.968 10.7998 35.4512 10.7998ZM18.8687 33.2893H14.8995V20.6815H18.8687V33.2893ZM16.8813 18.9343C16.4311 18.9318 15.9917 18.7959 15.6186 18.5439C15.2455 18.2919 14.9554 17.935 14.785 17.5183C14.6145 17.1015 14.5713 16.6437 14.6608 16.2024C14.7504 15.7612 14.9686 15.3563 15.288 15.039C15.6074 14.7216 16.0136 14.506 16.4554 14.4193C16.8972 14.3326 17.3548 14.3788 17.7704 14.5519C18.1861 14.725 18.5411 15.0174 18.7907 15.3921C19.0403 15.7668 19.1734 16.2071 19.173 16.6573C19.1772 16.9587 19.1208 17.2579 19.0069 17.5371C18.8931 17.8162 18.7242 18.0696 18.5103 18.2821C18.2965 18.4946 18.042 18.6618 17.7622 18.7739C17.4823 18.8859 17.1827 18.9405 16.8813 18.9343ZM33.4987 33.3003H29.5313V26.4125C29.5313 24.3811 28.6678 23.7541 27.5532 23.7541C26.3762 23.7541 25.2212 24.6415 25.2212 26.4638V33.3003H21.252V20.6906H25.069V22.4378H25.1203C25.5035 21.6623 26.8455 20.3368 28.8933 20.3368C31.108 20.3368 33.5005 21.6513 33.5005 25.5013L33.4987 33.3003Z"
        fill="white"
      />
    </svg>
  );
}

export function WhatsappShare() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.600098" width="48" height="48" rx="24" fill="#25d366" />
      <path
        d="M10.042 37.9627L12.0999 30.49C10.8276 28.2966 10.1602 25.8126 10.1671 23.2732C10.1671 15.3161 16.6744 8.84668 24.6626 8.84668C28.5419 8.84668 32.1849 10.3481 34.9172 13.0743C37.6563 15.8005 39.165 19.4262 39.158 23.2802C39.158 31.2373 32.6507 37.7067 24.6556 37.7067H24.6487C22.2223 37.7067 19.8377 37.0978 17.7173 35.9493L10.042 37.9627ZM18.0858 33.3407L18.5237 33.6036C20.373 34.6969 22.4935 35.2712 24.6556 35.2781H24.6626C31.302 35.2781 36.7108 29.9019 36.7108 23.2871C36.7108 20.0835 35.4594 17.0736 33.186 14.8041C30.9127 12.5346 27.8815 11.2892 24.6626 11.2892C18.0232 11.2822 12.6143 16.6585 12.6143 23.2732C12.6143 25.5358 13.247 27.7431 14.4567 29.6528L14.7417 30.1094L13.5251 34.5308L18.0858 33.3407Z"
        fill="white"
      />
      <path
        d="M10.5493 37.4574L12.5377 30.2407C11.3071 28.1303 10.6606 25.7294 10.6606 23.28C10.6675 15.5996 16.9454 9.35156 24.6624 9.35156C28.4097 9.35156 31.9205 10.8046 34.5624 13.4339C37.2043 16.0632 38.6573 19.5643 38.6573 23.2869C38.6573 30.9672 32.3724 37.2153 24.6624 37.2153H24.6554C22.3125 37.2153 20.0113 36.6271 17.9674 35.52L10.5493 37.4574Z"
        fill="url(#paint1_linear_1423_56147)"
      />
      <path
        d="M10.042 37.9627L12.0999 30.49C10.8276 28.2966 10.1602 25.8126 10.1671 23.2732C10.1671 15.3161 16.6744 8.84668 24.6626 8.84668C28.5419 8.84668 32.1849 10.3481 34.9172 13.0743C37.6563 15.8005 39.165 19.4262 39.158 23.2802C39.158 31.2373 32.6507 37.7067 24.6556 37.7067H24.6487C22.2223 37.7067 19.8377 37.0978 17.7173 35.9493L10.042 37.9627ZM18.0858 33.3407L18.5237 33.6036C20.373 34.6969 22.4935 35.2712 24.6556 35.2781H24.6626C31.302 35.2781 36.7108 29.9019 36.7108 23.2871C36.7108 20.0835 35.4594 17.0736 33.186 14.8041C30.9127 12.5346 27.8815 11.2892 24.6626 11.2892C18.0232 11.2822 12.6143 16.6585 12.6143 23.2732C12.6143 25.5358 13.247 27.7431 14.4567 29.6528L14.7417 30.1094L13.5251 34.5308L18.0858 33.3407Z"
        fill="url(#paint2_linear_1423_56147)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0401 17.24C20.769 16.638 20.4839 16.6242 20.2267 16.6173C20.0181 16.6104 19.7748 16.6104 19.5315 16.6104C19.2881 16.6104 18.8988 16.7003 18.5651 17.0601C18.2314 17.4199 17.2998 18.2917 17.2998 20.07C17.2998 21.8413 18.5999 23.5572 18.7806 23.7994C18.9614 24.0416 21.2904 27.7987 24.9681 29.2448C28.0271 30.4488 28.6528 30.2066 29.3133 30.1443C29.9738 30.0821 31.4546 29.2725 31.7605 28.4284C32.0594 27.5842 32.0594 26.8646 31.9691 26.7124C31.8787 26.5602 31.6354 26.4702 31.2738 26.2903C30.9123 26.1104 29.1325 25.2386 28.7988 25.1141C28.4651 24.9964 28.2218 24.9342 27.9854 25.294C27.7421 25.6538 27.0469 26.4633 26.8383 26.7055C26.6297 26.9477 26.4142 26.9753 26.0527 26.7954C25.6912 26.6155 24.5232 26.235 23.1397 25.0034C22.0621 24.0485 21.3321 22.8653 21.1235 22.5055C20.915 22.1457 21.1027 21.952 21.2834 21.7721C21.4433 21.6129 21.645 21.35 21.8257 21.1424C22.0065 20.9349 22.0691 20.7826 22.1872 20.5405C22.3054 20.2983 22.2498 20.0907 22.1594 19.9108C22.0691 19.7378 21.3599 17.9527 21.0401 17.24Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1423_56147"
          x1="24.5989"
          y1="47.9976"
          x2="24.5989"
          y2="-0.0016794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#20B038" />
          <stop offset="1" stop-color="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1423_56147"
          x1="24.6026"
          y1="37.456"
          x2="24.6026"
          y2="9.35058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#20B038" />
          <stop offset="1" stop-color="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1423_56147"
          x1="24.6029"
          y1="37.96"
          x2="24.6029"
          y2="8.84668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
