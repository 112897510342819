import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import ActionButton from '../../../ActionButton';
import BlurHashImage from '../../../BlurHashImage';
import CustomAnchor from '../../../CustomAnchor';
import InformationBlockLoading from '../../../skeleton/InformationBlockLoading';

function InformationBlock({
  title,
  description,
  imgSrc,
  blurhash,
  reverse,
  background = 'bg-ivory',
  darkBackground,
  imgLink = '/',
  size = 'LANDSCAPE',
  primaryAction,
  secondaryAction,
  instanceData
}) {
  const {
    state: { currentUser }
  } = useApp();

  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 'SQUARE':
        return 'square-img';
      case 'PORTRAIT':
        return 'portrait-img';

      default:
        return 'landscape-img';
    }
  };
  return (
    <section className={`information-block ${background}`}>
      <div className="container">
        <div className={`information-wrapper ${reverse && 'info-rev'}`}>
          <div className="information-content">
            {title && (
              <h3
                className={`display-h3 ${
                  darkBackground ? 'text-white-90' : 'text-black-90'
                }`}
              >
                {title}
              </h3>
            )}
            {description && (
              <p
                className={`description  ${
                  darkBackground ? 'text-white-90' : 'text-black-90'
                }`}
              >
                {description}
              </p>
            )}
            <div className="button-wrapper">
              {secondaryAction?.title && (
                <ActionButton
                  secondaryBtn
                  type={secondaryAction?.type}
                  internalPageType={secondaryAction?.internalPageType}
                  url={secondaryAction?.url}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_INFORMATION_SECONDARY_BTN_CLICKED
                    )
                  }
                  icon={secondaryAction?.asset}
                >
                  {secondaryAction?.title}
                </ActionButton>
              )}
              {primaryAction?.title && (
                <ActionButton
                  primaryBtn
                  type={primaryAction?.type}
                  internalPageType={primaryAction?.internalPageType}
                  url={primaryAction?.url}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_INFORMATION_PRIMARY_BTN_CLICKED
                    )
                  }
                  icon={primaryAction?.asset}
                >
                  {primaryAction?.title}
                </ActionButton>
              )}
            </div>
          </div>
          {imgSrc && (
            <CustomAnchor
              href={imgLink}
              className={`information-image relative  ${renderSwitch(size)}`}
            >
              <BlurHashImage
                src={imgSrc}
                blurhash={blurhash}
                fill
                alt={title}
                className="cover"
              />
            </CustomAnchor>
          )}
        </div>
      </div>
    </section>
  );
}

InformationBlock.Loading = function Loading() {
  return <InformationBlockLoading />;
};

export default InformationBlock;
