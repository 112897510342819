import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Badge, Button, Col, Row } from 'antd';
import axios from 'axios';
import { uniqBy, upperCase } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import {
  ARTICLE_ACTION_TYPE,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MEMBERS_ONLY,
  ROUTES,
  TRACK_VALUE
} from '../../../../common/constants';
import { handleDatePacificFormat } from '../../../../common/utils';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';
import ArticleListLoading from '../../../skeleton/ArticleListLoading';

function ArticleList({
  sectionHeading,
  headerDescription,
  title,
  description,
  actionName,
  actionType
}) {
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();
  const [articleData, setArticleData] = useState([]);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const perPage = 15;
  const router = useRouter();

  useEffect(() => {
    const url = `${
      process.env.NEXT_PUBLIC_ARTICLE_REST_API_URL
    }/main-server/api/v1/article?page=${page}&per_page=${perPage}&is_premium=${true}`;
    const headers = {
      'x-api-key': process.env.NEXT_PUBLIC_ARTICLE_REST_API_HEADER_KEY
    };
    axios
      .get(url, { headers })
      .then((response) => {
        setLoadMoreBtn(response?.data?.articles?.length < perPage);
        setArticleData(
          uniqBy([...articleData, ...response?.data?.articles], 'id')
        );
        setLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleViewMore = () => {
    router.push(ROUTES.ARTICLES);
  };

  const handleAnalytics = (category, action, item) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        title,
        authorName: item?.author?.[0]?.author_name,
        href: item?.link
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        title,
        authorName: item?.author?.[0]?.author_name,
        href: item?.link
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <section className="article-list-section">
      {isLoading ? (
        <ArticleListLoading />
      ) : (
        <div className="container">
          <div className="section-header">
            {title ? <h3>{sectionHeading}</h3> : null}
            {description ? <p>{headerDescription}</p> : null}
          </div>
          <Row
            gutter={[
              { xs: 8, sm: 8, md: 16, lg: 32 },
              { xs: 16, sm: 16, md: 32, lg: 32 }
            ]}
          >
            {articleData?.map((item) => {
              return (
                <Col
                  key={item?.id}
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <CustomAnchor
                    key={item?.id}
                    href={item?.link}
                    className="article-list-card"
                    onClick={() =>
                      handleAnalytics(
                        MATOMO_CATEGORY.VIEW_DETAIL,
                        MATOMO_ACTION.VIEW_ARTICLE,
                        item
                      )
                    }
                  >
                    <div className="article-card-img ">
                      <Image src={item?.thumbnail_image} fill alt="" />
                    </div>
                    <div className="article-info">
                      <Badge className="badge-md text-white-90 bg-n-900 premium-tag ml-4">
                        <span className="text-label-xs">{MEMBERS_ONLY}</span>
                      </Badge>
                      <p>{item?.title}</p>
                      <div className="article-auth-name">
                        {upperCase(item?.author?.[0]?.author_name)}
                      </div>
                      <span className="article-auth-date text-n-600">
                        {handleDatePacificFormat(item?.published)}
                      </span>
                    </div>
                  </CustomAnchor>
                </Col>
              );
            })}
          </Row>
          {articleData?.length > 0 &&
            (actionType !== ARTICLE_ACTION_TYPE[1].value ? (
              !loadMoreBtn && (
                <div className="article-view-btn text-center mt-20">
                  <Button onClick={handleLoadMore} type="primary">
                    {actionName}
                  </Button>
                </div>
              )
            ) : (
              <div className="article-view-btn text-center mt-20">
                <Button onClick={handleViewMore} type="primary">
                  {actionName}
                </Button>
              </div>
            ))}
        </div>
      )}
    </section>
  );
}

ArticleList.Loading = function Loading() {
  return <ArticleListLoading />;
};

export default ArticleList;
