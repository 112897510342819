import React from 'react';

function SubscriptionBlockLoading() {
  return (
    <section className="subscription-block-wrapper section-padding skeleton-loader">
      <div className="container">
        <div className="subscription-block">
          <div className="sb-block skeleton" />
          <div className="sb-content text-heading">
            <div className="secondary-text skeleton" />
            <div className="title skeleton" />
            <div className="button skeleton" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionBlockLoading;
