import { Avatar, Button } from 'antd';
import { useRouter } from 'next/navigation';
import { ACTION_BUTTON_TYPES, PAGE_SLUG } from '../../../../common/constants';
import SectionHeader from '../../SectionHeader/SectionHeader';

function CtaButtonList({
  sectionHeading,
  headerDescription,
  bottomSeparator,
  data = []
}) {
  const router = useRouter();
  const handleClick = ({ type, url, internalPageType }) => {
    if (type === ACTION_BUTTON_TYPES.INTERNAL) {
      router.push(
        `${PAGE_SLUG[internalPageType] ?? ''}${url === '/' ? '' : '/'}${url}`
      );
    } else if (
      typeof window !== 'undefined' &&
      type === ACTION_BUTTON_TYPES.EXTERNAL_IN_APP
    ) {
      // open the url in the same tab when action type is external in app
      window.open(url, '_self');
    } else window.open(url, '_blank');
  };
  return (
    <section className="category-list">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerDescription={headerDescription}
        />
        <div className="button-list">
          {data?.map((item) => {
            return (
              <Button
                key={item?.id}
                onClick={() => {
                  handleClick({
                    type: item?.type,
                    url: item?.url,
                    internalPageType: item?.internalPageType
                  });
                }}
                className={`cs-button mb-8 d-flex justify-around ${
                  item?.buttonType === 'PRIMARY' && 'primary-list-devider'
                } ${
                  bottomSeparator &&
                  item?.buttonType !== 'PRIMARY' &&
                  'secondary-list-devider'
                }`}
                type={item?.buttonType === 'PRIMARY' ? 'primary' : 'secondary'}
              >
                {item.title}
                {item?.img ? (
                  <Avatar className="ml-8" src={item?.img} size={22} />
                ) : (
                  ''
                )}
              </Button>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default CtaButtonList;
