import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  ACTION_BUTTON_TYPES,
  DEVICES,
  PAGE_SLUG
} from '../../../../common/constants';
import useDevice from '../../../../common/useDevice';
import BlurHashImage from '../../../BlurHashImage';
import BannerImageLoading from '../../../skeleton/BannerImageLoading';

function BannerImage({
  title,
  primaryAction,
  foregroundImages,
  backgroundImages,
  imageHeights
}) {
  const router = useRouter();
  const deviceHook = useDevice();
  const [device, setDevice] = useState(DEVICES.DESKTOP);
  const handleClick = () => {
    if (!primaryAction?.url) return;
    const { type, internalPageType, url } = primaryAction;
    if (type === ACTION_BUTTON_TYPES.INTERNAL) {
      router.push(
        `${PAGE_SLUG[internalPageType] ?? ''}${url === '/' ? '' : '/'}${url}`
      );
    } else if (
      typeof window !== 'undefined' &&
      type === ACTION_BUTTON_TYPES.EXTERNAL_IN_APP
    ) {
      // open the url in the same tab when action type is external in app
      window.open(url, '_self');
    } else window.open(url, '_blank');
  };

  const classNames = {
    [DEVICES.MOBILE]: 'mobile-img',
    [DEVICES.TABLET]: 'tablet-img',
    [DEVICES.DESKTOP]: 'desktop-img'
  };

  const height = Number(imageHeights?.[device]) ?? '100vh';
  const backgroundImage = backgroundImages?.[device] ?? DEVICES.DESKTOP;

  useEffect(() => {
    setDevice(deviceHook);
  }, [deviceHook]);

  return (
    <div
      onClick={handleClick}
      className={`banner bg-n-600 ${primaryAction?.url ? 'pointer' : ''}`}
      style={{ height }}
    >
      <BlurHashImage
        src={backgroundImage}
        fill
        alt={title}
        className="cover"
        priority
        withBlur={false}
      />
      <div className="container">
        {foregroundImages?.map((image) => (
          <React.Fragment key={image?.device}>
            {image?.device === device && image?.src && (
              <BlurHashImage
                src={image?.src}
                blurhash={image?.blurhash}
                fill
                className={
                  classNames[image?.device] ?? classNames[DEVICES.DESKTOP]
                }
                withBlur={false}
                alt={image?.title}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

BannerImage.Loading = function Loading() {
  return <BannerImageLoading />;
};

export default BannerImage;
