import { Carousel } from 'antd';
import CategorySliderLoading from '../../../skeleton/CategorySliderLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CategoryCard from './Category';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  variableWidth: true,
  draggable: true,
  loop: true,
  scrollOverflow: true,
  smooth: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};
function CategorySlider({
  sectionHeading,
  headerLink,
  headerDescription,
  data = []
}) {
  return (
    <section className="category-slider">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item?.id}>
                <CategoryCard
                  href={item.href}
                  topic={item.topic}
                  backgroundColor={item.backgroundColor}
                  textColor={item.textColor}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}

CategorySlider.Loading = function Loading() {
  return <CategorySliderLoading settings={settings} />;
};

export default CategorySlider;
