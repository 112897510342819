import React from 'react';

function SubscriptionHeaderLoading() {
  return (
    <div className="subscription-header skeleton-loader">
      <div className="container">
        <div className="sh-header-wrapper">
          <div className="title skeleton" />
          <div className="button skeleton" />
        </div>
      </div>
    </div>
  );
}

export default SubscriptionHeaderLoading;
