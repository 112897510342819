import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Tag } from 'antd';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';

function TagCard({ className, id, name, icon, href = '#' }) {
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action, statusSuccess) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        id,
        name
      }),
      value: statusSuccess
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        id,
        name
      }),
      value: statusSuccess
    });
  };

  return (
    <div
      onClick={() =>
        handleAnalytics(
          MATOMO_CATEGORY.VIEW_DETAIL,
          MATOMO_ACTION.VIEW_TAG,
          TRACK_VALUE.SUCCESS
        )
      }
    >
      <CustomAnchor href={href}>
        <Tag className={className} icon={icon}>
          {name}
        </Tag>
      </CustomAnchor>
    </div>
  );
}
export default TagCard;
