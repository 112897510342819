import { Carousel } from 'antd';
import React from 'react';

function FeaturedCarouselLoading({ settings }) {
  const videoData = [1, 2, 3, 4];
  return (
    <section className="featured-carousel skeleton-loader">
      <Carousel {...settings}>
        {videoData.map((item) => (
          <div className="featured-video-card skeleton-loader" key={item?.id}>
            <div className="fvc-image" />
            <div className="fvc-details">
              <div className="fvc-description">
                <div className="badge skeleton" />
                <div className="tag-carousel mt-12 mb-12 skeleton" />
                <div className="heading skeleton" />
                <div className="date skeleton mt-8" />
                <div className="description text-n-0 skeleton" />
              </div>
              <div className="slider-button">
                <div className="button skeleton" />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
}

export default FeaturedCarouselLoading;
