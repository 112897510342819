import CategoryListLoading from '../../../skeleton/CategoryListLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CategoryCard from './Category';

function CategoryList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = []
}) {
  return (
    <section className="category-list">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />

        {data?.map((item) => {
          return (
            <CategoryCard
              key={item?.id}
              list
              href={item?.href}
              topic={item?.topic}
              backgroundColor={item?.backgroundColor}
              textColor={item?.textColor}
            />
          );
        })}
      </div>
    </section>
  );
}

CategoryList.Loading = function Loading() {
  return <CategoryListLoading />;
};

export default CategoryList;
