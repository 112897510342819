import { useLazyQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import { uniqBy } from 'lodash';
import { useSession } from 'next-auth/react';
import React, { useState } from 'react';
import {
  getVideoProgressInPercentage,
  secondsToMinute,
  videosMapper
} from '../../../../common/utils';
import { GET_VIDEOS } from '../../../../graphql/Queries';
import VideoGridLoading from '../../../skeleton/VideoGridLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import { SORT_BY } from '../filter/Filter';
import VideoCard from './VideoCard';
import VideoGrid from './VideoGrid';

const videoDataHandler = (data) =>
  data?.map((video) => videosMapper(video)) ?? [];

function VideoGridWithPagination({
  data,
  actionButton,
  autoGenerateData,
  autoGenerateByType,
  sectionHeading,
  headerLink,
  headerDescription,
  showVideoProgressBar,
  videosWatchTimes,
  actionTitle
}) {
  const LIMIT = 8;
  const { data: sessionData } = useSession();
  const [videos, setVideos] = useState(data ?? []);
  const [displayActionBtn, setDisplayActionBtn] = useState(false);

  const [fetchVideos, { loading }] = useLazyQuery(GET_VIDEOS, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        token: sessionData?.accessToken
      }
    },
    onCompleted: (res) => {
      const allVideos = videoDataHandler(res?.videos?.videos);
      setVideos(uniqBy([...videos, ...allVideos]), 'id');
      setDisplayActionBtn(res?.videos?.videos?.length < LIMIT);
    }
  });

  const handleLoadMore = () => {
    fetchVideos({
      variables: {
        filter: {
          skip: videos?.length,
          limit: LIMIT,
          topicIds:
            autoGenerateByType === 'TOPIC' ? [autoGenerateData?.topicId] : null,
          tagId: autoGenerateByType === 'TAG' ? autoGenerateData?.tagId : null,
          collectionId:
            autoGenerateByType === 'COLLECTION'
              ? autoGenerateData?.collectionId
              : ''
        },
        sort: {
          sortOn: SORT_BY.CREATED_AT,
          sortBy: 'DESC'
        }
      }
    });
  };
  return (
    <section className="video-grid">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={!actionButton && headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 16 },
            { xs: 16, sm: 16, md: 32, lg: 32 }
          ]}
        >
          {videos?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
              >
                <VideoCard
                  src={item?.src}
                  videoThumbnail={item?.videoThumbnail}
                  blurhash={item?.blurhash}
                  defaultValue={item?.defaultValue}
                  time={secondsToMinute(item?.time)}
                  category={item?.category}
                  categoryHref={item?.categoryHref}
                  collection={item?.collection}
                  title={item?.title}
                  date={item?.date}
                  tags={item?.tags}
                  tagBackground={item?.tagBackground}
                  tagTextColor={item?.tagTextColor}
                  href={item?.href}
                  {...(showVideoProgressBar && {
                    defaultValue: getVideoProgressInPercentage(
                      videosWatchTimes?.[item?.id],
                      item?.time
                    )
                  })}
                  isPremium={item?.isPremium}
                  instanceData={item?.instanceData}
                  instanceId={item?.instanceId}
                  instanceType={item?.instanceType}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      {actionButton && !displayActionBtn && (
        <div className="load-more-btn text-center mt-20">
          <Button loading={loading} onClick={handleLoadMore} type="primary">
            {actionTitle || 'Load More'}
          </Button>
        </div>
      )}
    </section>
  );
}

VideoGrid.Loading = function Loading() {
  return <VideoGridLoading />;
};

export default VideoGridWithPagination;
