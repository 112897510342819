import React from 'react';

function CtaBannerLoading() {
  return (
    <section className="ctaBanner-section bg-n-600 skeleton-loader">
      <div className="container-rnp">
        <div className="container-marketing">
          <div className="cta-bnnner-block ctabannerbackground bg-n-1000">
            <div className="cta-content">
              <div className="title skeleton" />
              <p className="description skeleton" />
              <p className="description skeleton" />
              <div className="button skeleton" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CtaBannerLoading;
