import React from 'react';

function CategoryListLoading() {
  const CategorySliderData = [1, 2, 3, 4];
  return (
    <section className="category-list skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
        {CategorySliderData.map((item) => (
          <div className="category-card category-list skeleton" key={item} />
        ))}
      </div>
    </section>
  );
}

export default CategoryListLoading;
