import { Carousel } from 'antd';
import React from 'react';

function FormCarouselLoading({ settings }) {
  const formData = [1, 2, 3, 4];
  return (
    <section className="form-card-slider skeleton-loader">
      <div className="container">
        <div className="section-header">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
          </div>
        </div>
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {formData.map((item) => (
            <div key={item?.id}>
              <div className="form-card bg-n-700">
                <div className="vc-description">
                  <div className="fc-title">
                    <div className="icon skeleton" />{' '}
                    <div className="title skeleton" />
                  </div>
                  <div className="form-card-button-wrapper">
                    <div className="skeleton button" />
                    <div className="skeleton button" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default FormCarouselLoading;
