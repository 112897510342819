import { decode } from 'blurhash';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

const getDataUrlFromBlurhash = (
  hashCode,
  width = 64,
  height = 64,
  options = { outputType: 'jpeg', quality: 0.7 }
) => {
  if (hashCode) {
    const pixels = decode(hashCode, width, height);
    const { outputType, quality } = options;
    if (pixels) {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', `${width}px`);
      canvas.setAttribute('height', `${height}px`);
      const ctx = canvas.getContext('2d');
      const imageData = new ImageData(pixels, width, height);
      ctx.putImageData(imageData, 0, 0);
      const dataUri = canvas.toDataURL(`image/${outputType}`, quality);
      return dataUri;
    }
  }
  return '';
};

function BlurHashImage({ blurhash, withBlur = true, ...rest }) {
  const ref = useRef();
  const [blurDataURL, setBlurDataURL] = useState('');
  useEffect(() => {
    if (withBlur)
      setBlurDataURL(
        getDataUrlFromBlurhash(
          blurhash ?? 'U27AxY0g~Vax~W57%MRP0hadjbM{xa-TNbR*',
          64,
          64
        )
      );
  }, [blurhash, withBlur]);
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      ref={ref}
      {...(blurDataURL && {
        placeholder: 'blur',
        blurDataURL
      })}
      onLoad={() => {
        if (ref.current) {
          ref.current.style.backgroundImage = 'none';
        }
      }}
      {...rest}
    />
  );
}

export default BlurHashImage;
