import React from 'react';

function CollectionListLoading() {
  const videoData = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <section className="collection-list-section skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
      </div>
      <div className="container-rnp">
        <div className="">
          {videoData.map((item) => (
            <div className="collection-list-item " key={item}>
              <div className="collection-card collection-list skeleton-loader">
                <div className="collection-img skeleton" />
                <div className="collection-dec">
                  <div className="category skeleton" />
                  <div className="part skeleton" />
                  <div className="paragraph skeleton" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CollectionListLoading;
