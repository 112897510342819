import React from 'react';

function DurationTime({ seconds }) {
  const pad = (number) => {
    return `0${number}`.slice(-2);
  };

  // eslint-disable-next-line no-shadow
  const format = (seconds) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  };
  return (
    <div className="text-md">
      <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>
    </div>
  );
}

export default DurationTime;
