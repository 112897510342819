function ArrowProgress({ playValue }) {
  return (
    <div className="arrow-progress">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="48"
          height="48"
          rx="24"
          fill="var(--tq-neutral-500)"
          fillOpacity="0.2"
        />
        <path
          d="M17 24H31"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 31L31 24L24 17"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        className="progress-bar"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1 -1 34 34"
      >
        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className="progress-bar__background"
        />

        <circle
          cx="16"
          cy="16"
          r="15.9155"
          style={{ strokeDasharray: `${100 + playValue}px` }}
          className="progress-bar__progress
                 js-progress-bar"
        />
      </svg>
    </div>
  );
}
export default ArrowProgress;
