import { Avatar, Button } from 'antd';
import isFunction from 'lodash/isFunction';
import { useRouter } from 'next/router';
import React from 'react';
import { ACTION_BUTTON_TYPES, PAGE_SLUG } from '../common/constants';

function ActionButton({
  children,
  type,
  url,
  onClick,
  internalPageType,
  icon,
  primaryBtn,
  secondaryBtn,
  className,
  ...rest
}) {
  const router = useRouter();
  const handleClick = () => {
    if (isFunction(onClick)) onClick();
    if (type === ACTION_BUTTON_TYPES.INTERNAL) {
      router.push(
        `${PAGE_SLUG[internalPageType] ?? ''}${url === '/' ? '' : '/'}${url}`
      );
    } else if (
      typeof window !== 'undefined' &&
      type === ACTION_BUTTON_TYPES.EXTERNAL_IN_APP
    ) {
      // open the url in the same tab when action type is external in app
      window.open(url, '_self');
    } else window.open(url, '_blank');
  };

  return (
    <Button
      onClick={handleClick}
      className={`cs-button ${className}`}
      type={`${primaryBtn ? 'primary' : 'default'}`}
      {...rest}
    >
      {icon?.url ? <Avatar className="mr-8" src={icon?.url} size={22} /> : ''}{' '}
      {children}
    </Button>
  );
}

export default ActionButton;
