import { Col, Row } from 'antd';
import React from 'react';

function FaqModuleLoading() {
  return (
    <section className="faq-section skeleton-loader">
      <div className="container">
        <div className="container-marketing">
          <div className="faq-section-title mb-32">
            <div className="title skeleton" />
            <p className="subtitle skeleton" />
          </div>
          <div className="cs-accordion">
            <Row gutter={[{ md: 32, xs: 0 }, 0]}>
              <Col md={{ span: 24 }} lg={{ span: 12 }}>
                <div className="ant-collapse">
                  <div className="ant-collapse-item skeleton" />
                </div>
                <div className="ant-collapse">
                  <div className="ant-collapse-item skeleton" />
                </div>
                <div className="ant-collapse">
                  <div className="ant-collapse-item skeleton" />
                </div>
              </Col>
              <Col md={{ span: 24 }} lg={{ span: 12 }}>
                <div className="ant-collapse">
                  <div className="ant-collapse-item skeleton" />
                </div>
                <div className="ant-collapse">
                  <div className="ant-collapse-item skeleton" />
                </div>
                <div className="ant-collapse">
                  <div className="ant-collapse-item skeleton" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqModuleLoading;
