import { Spin } from 'antd';
import React from 'react';

function LoaderComponent(props) {
  const {
    spinning = true,
    size = 'large',
    children,
    setHeight = '100vh',
    className = '',
    ...rest
  } = props;
  return (
    <Spin
      spinning={spinning}
      size={size}
      className={`d-flex justify-center align-center flex-vertical w-full ${className}`}
      style={{
        height: setHeight
      }}
      {...rest}
    >
      {children}
    </Spin>
  );
}
export default LoaderComponent;
