import { useEffect, useState } from 'react';
import { BREAKPOINTS, DEVICES } from './constants';

const getDevice = (windowWidth) => {
  if (windowWidth <= BREAKPOINTS[DEVICES.MOBILE]) return DEVICES.MOBILE;
  if (windowWidth <= BREAKPOINTS[DEVICES.TABLET]) return DEVICES.TABLET;
  return DEVICES.DESKTOP;
};

const defaultDevice = () => {
  if (typeof window !== 'undefined') return getDevice(window?.innerWidth);
  return DEVICES.MOBILE;
};

const useDevice = () => {
  const [device, setDevice] = useState(() => defaultDevice());
  useEffect(() => {
    const updateMedia = () => {
      setDevice(getDevice(window?.innerWidth));
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return device;
};

export default useDevice;
