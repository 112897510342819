import { Carousel } from 'antd';
import React from 'react';

function VideoCarouselLoading({ settings }) {
  const videoData = [1, 2, 3, 4, 5];
  return (
    <section className="skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
      </div>
      <div className="one-side-carousel">
        <div className="w-full video-carousel shadow-arrow">
          <Carousel {...settings}>
            {videoData.map((item) => (
              <div className="video-card skeleton-loader" key={item}>
                <div className="video-image skeleton" />
                <div className="vc-description">
                  <div className="part skeleton" />
                  <div className="category skeleton" />
                  <div className="paragraph skeleton" />
                  <div className="paragraph skeleton" />
                  <div className="paragraph skeleton" />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default VideoCarouselLoading;
