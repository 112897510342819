import React from 'react';

function CollectionDetailLoading() {
  const formData = [1, 2, 3, 4];
  return (
    <section className="collection-items-section skeleton-loader">
      <div className="collection-item-container">
        <div className="cover-img skeleton" />
        <div className="collection-items-details">
          <div className="collection-item-list-wrapper">
            {formData.map((item) => (
              <div key={item?.id}>
                <div key={item?.id} className="collection-item-list">
                  <div className="d-flex gap-8">
                    <div className="text-small text-black-90 skeleton" />
                    <div className="time-stemp  skeleton" />
                  </div>
                  <div className="episode-description-wrapper">
                    <div className="episode-description">
                      <div className="title skeleton" />
                      <p className="text-small skeleton" />
                    </div>
                    <div className="arrow-progress skeleton" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CollectionDetailLoading;
