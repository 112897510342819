import isBoolean from 'lodash/isBoolean';
import React, { useEffect, useState } from 'react';
import { DEVICES, MODULE_TYPES } from '../common/constants';
import useDevice from '../common/useDevice';
import CtaBanner from './metaComponent/marketingComponent/ctaModule/CtaBanner';
import FaqModule from './metaComponent/marketingComponent/faqModule/FaqModule';
import HeroBlock from './metaComponent/marketingComponent/heroModules/HeroBlock';
import HtmlTextForm from './metaComponent/marketingComponent/htmlTextModules/HtmlTextForm';
import InformationBlock from './metaComponent/marketingComponent/informationModule/InformationBlock';
import ArticleList from './metaComponent/modulesComponent/article/ArticleList';
import CategoryCard from './metaComponent/modulesComponent/categoryModules/Category';
import CategoryGrid from './metaComponent/modulesComponent/categoryModules/CategoryGrid';
import CategoryList from './metaComponent/modulesComponent/categoryModules/CategoryList';
import CategorySlider from './metaComponent/modulesComponent/categoryModules/CategorySlider';
import CollectionCarousel from './metaComponent/modulesComponent/collectionModules/CollectionCarousel';
import CollectionDetail from './metaComponent/modulesComponent/collectionModules/CollectionDetail';
import CollectionGrid from './metaComponent/modulesComponent/collectionModules/CollectionGrid';
import CollectionList from './metaComponent/modulesComponent/collectionModules/CollectionList';
import CollectionPillCarousel from './metaComponent/modulesComponent/collectionModules/CollectionPillCarousel';
import BannerImage from './metaComponent/modulesComponent/ctaModule/BannerImage';
import CtaButtonList from './metaComponent/modulesComponent/ctaModule/CtaButtonList';
import CtaCard from './metaComponent/modulesComponent/ctaModule/CtaCard';
import FeaturedCarousel from './metaComponent/modulesComponent/featuredModules/FeaturedCarousel';
import FeaturedCollection from './metaComponent/modulesComponent/featuredModules/FeaturedCollection';
import FeaturedCollectionCarousel from './metaComponent/modulesComponent/featuredModules/FeaturedCollectionCarousel';
import FeaturedVideoCard from './metaComponent/modulesComponent/featuredModules/FeaturedVideoCard';
import FormCarousel from './metaComponent/modulesComponent/formModules/FormCarousel';
import FormDisplay from './metaComponent/modulesComponent/formModules/FormDisplay';
import PodcastInfo from './metaComponent/modulesComponent/podcastModules/PodcastInfo';
import PodcastList from './metaComponent/modulesComponent/podcastModules/PodcastList';
import SubscriptionBlock from './metaComponent/modulesComponent/subscriptionModules/SubscriptionBlock';
import SubscriptionHeader from './metaComponent/modulesComponent/subscriptionModules/SubscriptionHeader';
import SubscriptionList from './metaComponent/modulesComponent/subscriptionModules/SubscriptionList';
import TagCarousel from './metaComponent/modulesComponent/tagModule/TagCarousel';
import TagGrid from './metaComponent/modulesComponent/tagModule/TagGrid';
import TagHero from './metaComponent/modulesComponent/tagModule/TagHero';
import VideoCarousel from './metaComponent/modulesComponent/videoModules/VideoCarousel';
import VideoGridWithPagination from './metaComponent/modulesComponent/videoModules/VideoGridWithPagination';
import VideoInfo from './metaComponent/modulesComponent/videoModules/VideoInfo';
import VideoList from './metaComponent/modulesComponent/videoModules/VideoList';

const withContainer = (Component, responsiveContainer = false) => {
  return function container(props) {
    return (
      <div className={responsiveContainer ? 'container-rnp' : 'container'}>
        <Component {...props} />
      </div>
    );
  };
};

function Div({ ...props }) {
  const deviceHook = useDevice();
  const [device, setDevice] = useState(DEVICES.DESKTOP);
  useEffect(() => {
    setDevice(deviceHook);
  }, [deviceHook]);
  return <div suppressHydrationWarning className={props?.[device]} />;
}

const PlaceHolderComponents = {
  // Videos
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: VideoCarousel,
  [MODULE_TYPES.VIDEO_CAROUSEL]: VideoCarousel,
  [MODULE_TYPES.VIDEO_LIST]: VideoList,
  [MODULE_TYPES.VIDEO_GRID]: VideoGridWithPagination,
  [MODULE_TYPES.FEATURED_VIDEO]: withContainer(FeaturedVideoCard),
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: FeaturedCarousel,
  [MODULE_TYPES.VIDEO_PLAYER]: VideoInfo,
  // Podcasts
  [MODULE_TYPES.PODCAST_LIST]: PodcastList,
  [MODULE_TYPES.PODCAST_PLAYER]: PodcastInfo,
  // Collections
  [MODULE_TYPES.COLLECTION_CAROUSEL]: CollectionCarousel,
  [MODULE_TYPES.COLLECTION_GRID]: CollectionGrid,
  [MODULE_TYPES.COLLECTION_LIST]: CollectionList,
  [MODULE_TYPES.FEATURED_COLLECTION]: FeaturedCollection,
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: FeaturedCollectionCarousel,
  [MODULE_TYPES.COLLECTION_PILL_CAROUSEL]: withContainer(
    CollectionPillCarousel
  ),
  [MODULE_TYPES.COLLECTION_DETAIL]: CollectionDetail,
  // Topics
  [MODULE_TYPES.TOPIC_CAROUSEL]: CategorySlider,
  [MODULE_TYPES.TOPIC_LIST]: CategoryList,
  [MODULE_TYPES.TOPIC_GRID]: CategoryGrid,
  [MODULE_TYPES.FEATURED_TOPIC]: CategoryCard,
  // Tags
  [MODULE_TYPES.TAG_CAROUSEL]: withContainer(TagCarousel),
  [MODULE_TYPES.TAG_CLOUD]: withContainer(TagGrid),
  [MODULE_TYPES.FEATURED_TAG]: TagHero,
  // CTA
  [MODULE_TYPES.CTA_BLOCK]: CtaCard,
  [MODULE_TYPES.CTA_BANNER]: BannerImage,
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: CtaBanner,
  [MODULE_TYPES.HERO]: HeroBlock,
  [MODULE_TYPES.CTA_BUTTON_LIST]: CtaButtonList,
  // Information
  [MODULE_TYPES.INFORMATION]: InformationBlock,
  // FAQs
  [MODULE_TYPES.FAQ]: FaqModule,
  // Spacer
  [MODULE_TYPES.SPACER]: Div,
  // Html Text
  [MODULE_TYPES.HTML_TEXT_BLOCK]: withContainer(HtmlTextForm),
  // Forms
  [MODULE_TYPES.FORM_CAROUSEL]: FormCarousel,
  [MODULE_TYPES.FORM_DISPLAY]: FormDisplay,
  // subscription
  [MODULE_TYPES.SUBSCRIPTION_HEADER]: SubscriptionHeader,
  [MODULE_TYPES.SUBSCRIPTION_BLOCK]: SubscriptionBlock,
  [MODULE_TYPES.SUBSCRIPTION_LIST]: SubscriptionList,
  // Article
  [MODULE_TYPES.ARTICLE_LIST]: ArticleList
};

export default function ModuleComponent({
  data,
  extraProps,
  type,
  show,
  isLoading,
  ...rest
}) {
  const Component = PlaceHolderComponents[type];
  const SkeletonComponent =
    PlaceHolderComponents[type]?.Loading ?? React.Fragment;
  if (isLoading) return <SkeletonComponent />;
  if (!Component || (isBoolean(show) && !show)) return null;
  return <Component {...data} {...extraProps} {...rest} />;
}
