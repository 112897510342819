import { Carousel } from 'antd';
import CollectionCarouselLoading from '../../../skeleton/CollectionCarouselLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CollectionCard from './CollectionCard';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  variableWidth: true,
  draggable: true,
  loop: true,
  scrollOverflow: true,
  smooth: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};
function CollectionCarousel({
  sectionHeading,
  headerLink,
  headerDescription,
  collectionMd,
  collectionLg,
  data = []
}) {
  return (
    <section className="collection-carousel">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item?.id}>
                <CollectionCard
                  href={item?.href}
                  collectionMd={collectionMd}
                  collectionLg={collectionLg}
                  title={item?.title}
                  description={item?.description}
                  image={item?.coverImg}
                  blurhash={item?.blurhash}
                  videoCount={item?.videoCount}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}

CollectionCarousel.Loading = function Loading() {
  return <CollectionCarouselLoading settings={settings} />;
};

export default CollectionCarousel;
