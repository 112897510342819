import React from 'react';

function InformationBlockLoading() {
  return (
    <section className="information-block skeleton-loader">
      <div className="container">
        <div className="information-wrapper info-rev">
          <div className="information-content">
            <div className="display-h3 skeleton" />
            <p className="description skeleton" />
            <div className="button-wrapper">
              <div className="button skeleton" />
              <div className="button skeleton" />
            </div>
          </div>
          <div className="information-image skeleton" />
        </div>
      </div>
    </section>
  );
}

export default InformationBlockLoading;
