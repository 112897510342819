import { Carousel } from 'antd';
import FeaturedCarouselLoading from '../../../skeleton/FeaturedCarouselLoading';
import FeaturedVideoCard from './FeaturedVideoCard';

const settings = (showIndicator, centerMode) => ({
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  draggable: true,
  loop: true,
  centerPadding: '4%',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '40px',
        arrows: false
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        dots: !!showIndicator,
        arrows: false,
        variableHeight: true,
        fade: true
      }
    }
  ]
});
function FeaturedCarousel({
  data = [],
  centerMode = true,
  sectionTitle,
  showIndicator,
  showCTA
}) {
  return (
    <section
      className={`featured-carousel ${centerMode ? '' : 'center-false'}`}
    >
      {sectionTitle ? (
        <div className="display-h4 mb-8">{sectionTitle}</div>
      ) : null}

      <Carousel {...settings(showIndicator)}>
        {data?.map((item) => {
          return (
            <FeaturedVideoCard
              key={item?.id}
              tagName={item?.tagName}
              tagHref={item?.tagHref}
              tagBackground={item?.tagBackground}
              heading={item?.heading}
              date={item?.date}
              time={item?.time}
              description={item?.description}
              tagData={item?.tagData}
              src={item?.src}
              blurhash={item?.blurhash}
              href={item?.href}
              showCTA={showCTA}
              collections={item?.collections}
              isPremium={item?.isPremium}
            />
          );
        })}
      </Carousel>
    </section>
  );
}

FeaturedCarousel.Loading = function Loading() {
  return <FeaturedCarouselLoading settings={settings} />;
};
export default FeaturedCarousel;
