import React from 'react';

function VideoInfoLoading() {
  return (
    <section className="video-info-section skeleton-loader">
      <div className="player-wrapper " />
      <div className="container">
        <div className="video-info-wrapper">
          <div className="videoinfo-title">
            <div className="badge skeleton" />
            <div className="display-h4 skeleton" />
          </div>
          <div className="video-button-wrapper">
            <div className="button skeleton" />
          </div>
          <div className="video-info-tags d-flex gap-4">
            <div className="ant-tag skeleton" />
            <div className="ant-tag skeleton" />
            <div className="ant-tag skeleton" />
            <div className="ant-tag skeleton" />
          </div>
          <p className="paragraph skeleton" />
        </div>
      </div>
    </section>
  );
}

export default VideoInfoLoading;
