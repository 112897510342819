import { Typography } from 'antd';
import React from 'react';

const { Paragraph } = Typography;

function FeaturedVideoCardLoading() {
  return (
    <div className="featured-video-card skeleton-loader">
      <div className="fvc-image" />
      <div className="fvc-details">
        <div className="fvc-description">
          <div className="badge skeleton" />
          <div className="tag-carousel mt-12 mb-12 skeleton" />
          <div className="heading skeleton" />
          <div className="date skeleton mt-8" />
          <Paragraph className="description text-n-0 skeleton" />
        </div>
        <div className="slider-button">
          <div className="button skeleton" />
        </div>
      </div>
    </div>
  );
}

export default FeaturedVideoCardLoading;
