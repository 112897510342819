import { Button, Typography } from 'antd';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import {
  PAGE_GROUPS,
  SUBSCRIPTION_PLAN_KEYS
} from '../../../../common/constants';
import usePageGroup from '../../../../common/usePageGroup';
import { useApp } from '../../../../providers/AppContext';
import SubscriptionHeaderLoading from '../../../skeleton/SubscriptionHeaderLoading';

const { Title } = Typography;
function SubscriptionHeader({
  sectionHeading,
  primaryAction,
  btnLabel = 'SUBSCRIBE NOW'
}) {
  const {
    state: { currentUser, loading }
  } = useApp();
  const router = useRouter();
  const pathname = usePathname();

  const { getRandomPath } = usePageGroup();

  const hasActivePlan =
    currentUser &&
    ![SUBSCRIPTION_PLAN_KEYS.FREE, SUBSCRIPTION_PLAN_KEYS.GUEST].includes(
      currentUser?.currentPlan
    );

  const hasActiveHeavyWeightPlan =
    hasActivePlan &&
    [
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_ANNUALLY,
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_MONTHLY
    ].includes(currentUser?.currentPlan);

  const buttonText = useMemo(() => {
    if (loading) return '';
    if (hasActiveHeavyWeightPlan) return `CHANGE SUBSCRIPTION`;
    if (hasActivePlan) return `UPGRADE NOW`;
    return btnLabel;
  }, [hasActiveHeavyWeightPlan, hasActivePlan, loading, btnLabel]);

  const handleRedirect = () => {
    const path = getRandomPath(PAGE_GROUPS.SUBSCRIPTION);
    if (path)
      router.push({
        pathname: path,
        query: {
          redirectPath: pathname
        }
      });
  };

  return (
    <div className="subscription-header">
      <div className="container">
        <div className="sh-header-wrapper">
          <Title level={5}>{sectionHeading}</Title>
          {primaryAction && (
            <Button
              loading={loading}
              size="small"
              type="primary"
              onClick={handleRedirect}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

SubscriptionHeader.Loading = function Loading() {
  return <SubscriptionHeaderLoading />;
};

export default SubscriptionHeader;
