import { Col, Row } from 'antd';
import CollectionGridLoading from '../../../skeleton/CollectionGridLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CollectionCard from './CollectionCard';

function CollectionGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  collectionMd,
  collectionLg
}) {
  return (
    <section className="collection-grid">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 16 },
            { xs: 16, sm: 16, md: 32, lg: 32 }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xxl={{ span: 4 }}
              >
                <div className=" ">
                  <CollectionCard
                    href={item?.href}
                    videoCount={item?.videoCount}
                    title={item?.title}
                    description={item?.description}
                    image={item?.coverImg}
                    blurhash={item?.blurhash}
                    collectionMd={collectionMd}
                    collectionLg={collectionLg}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

CollectionGrid.Loading = function Loading() {
  return <CollectionGridLoading />;
};

export default CollectionGrid;
