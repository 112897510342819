import React from 'react';

function CtaCardLoading() {
  return (
    <section className="skeleton-loader">
      <div className="container-rnp">
        <div className="ctacard bg-n-600">
          <div className="ctaCard-img skeleton" />
          <div className="ctacard-dec">
            <div className="title skeleton" />
            <div className="date skeleton" />
            <p className="paragraph mb-4 skeleton" />
            <p className="paragraph mb-4 skeleton" />
            <p className="paragraph mb-4 skeleton" />
            <div className="cta-button-wrapper">
              <div className="button skeleton" />
              <div className="button skeleton" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CtaCardLoading;
