import React from 'react';

function CategoryCardLoading() {
  return (
    <div className="category-card category-landing skeleton">
      <div className="category-title" />
    </div>
  );
}

export default CategoryCardLoading;
