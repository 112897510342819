import { Col, Row } from 'antd';
import React from 'react';

function CollectionGridLoading() {
  const videoData = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <section className="collection-grid skeleton-loader">
      <div className="container">
        <div className="section-header skeleton-loader">
          <div className="sh-top mb-4">
            <div className="sh-title skeleton" />
            <span className="flex-1" />
            <div className="d-flex align-center text-n-900 button-m skeleton" />
          </div>
        </div>
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 16 },
            { xs: 16, sm: 16, md: 32, lg: 32 }
          ]}
        >
          {videoData.map((item) => (
            <Col
              key={item?.id}
              xs={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xxl={{ span: 4 }}
            >
              <div className="collection-card skeleton-loader">
                <div className="collection-img skeleton" />
                <div className="collection-dec">
                  <div className="category skeleton" />
                  <div className="part skeleton" />
                  <div className="paragraph skeleton" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default CollectionGridLoading;
