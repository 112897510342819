import React from 'react';

function PodcastInfoLoading() {
  return (
    <section className="video-info-section podcast-info-section skeleton-loader">
      <div className="podcast-segment skeleton" />
      <div className="player-wrapper" />
      <div className="custom-control-wrap">
        <div className="controlitem-bottom">
          <div className="duration-bar skeleton" />
          <div className="left-controls mt-4 skeleton" />
        </div>
        <div className="controlitem-middle">
          <div className="play-pause-btn">
            <div className="skip-btn skeleton" />
            <div className="play-pause-btn pointer skeleton" />
            <div className="skip-btn skeleton" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="video-info-wrapper">
          <div className="video-button-wrapper">
            <div className="button skeleton" />
            <div className="button skeleton" />
          </div>
          <div className="videoinfo-title">
            <div className="ant-typography skeleton mb-4" />
            <div className="display-h4 skeleton" />
          </div>
          <div className="paragraph-wrapper w-full">
            <div className="paragraph skeleton" />
            <div className="paragraph skeleton" />
            <div className="paragraph skeleton" />
            <div className="paragraph skeleton" />
            <div className="paragraph skeleton" />
            <div className="paragraph skeleton" />
            <div className="paragraph w-50 skeleton" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PodcastInfoLoading;
