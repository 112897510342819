import { Carousel } from 'antd';
import React from 'react';

function FeaturedCollectionCarouselLoading({ settings }) {
  const FeaturedCollectionData = [1, 2, 3, 4];
  return (
    <section className="featurecollection-slider">
      <section className="featured-collection skeleton-loader">
        <div className="container-rnp">
          <Carousel {...settings}>
            {FeaturedCollectionData.map((item) => (
              <div
                className="featured-collection-wrapper bg-n-700"
                key={item?.id}
              >
                <div className="featured-collection-img skeleton" />
                <div className="featured-collection-dec">
                  <div className="title skeleton" />
                  <div className="featured-collection-video-count skeleton" />
                  <p className="skeleton" />
                  <p className="skeleton" />
                  <p className="skeleton" />
                  <div className="button skeleton" />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </section>
  );
}

export default FeaturedCollectionCarouselLoading;
