import { LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, Spin } from 'antd';

function Loader() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24
      }}
      spin
    />
  );
  return (
    <div className="loader">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#ffffff'
          }
        }}
      >
        <Spin indicator={antIcon} />
      </ConfigProvider>
    </div>
  );
}
export default Loader;
