import CustomAnchor from '../../../CustomAnchor';
import TagHeroLoading from '../../../skeleton/TagHeroLoading';

function TagHero({
  background = 'bg-moss-2',
  title,
  textColor = 'text-black-90',
  href
}) {
  return (
    <CustomAnchor href={href}>
      <section className={`taghero ${background}`}>
        <div className={`title ${textColor}`}>{title}</div>{' '}
      </section>
    </CustomAnchor>
  );
}

TagHero.Loading = function Loading() {
  return <TagHeroLoading />;
};

export default TagHero;
