import { Carousel } from 'antd';
import {
  getVideoProgressInPercentage,
  secondsToMinute
} from '../../../../common/utils';
import VideoCarouselLoading from '../../../skeleton/VideoCarouselLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import VideoCard from './VideoCard';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  cssEase: 'linear',
  slidesToScroll: 1,
  slidesToShow: 4,
  variableWidth: true,
  draggable: true,
  scrollOverflow: true,
  smooth: true,
  loop: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};
function VideoCarousel({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  showVideoProgressBar,
  videosWatchTimes
}) {
  return (
    <section>
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel">
        <div className="w-full video-carousel shadow-arrow">
          <Carousel {...settings}>
            {data?.map((item) => {
              return (
                <div key={item?.id} aria-hidden="true">
                  <VideoCard
                    src={item?.src}
                    videoThumbnail={item?.videoThumbnail}
                    blurhash={item?.blurhash}
                    time={secondsToMinute(item?.time)}
                    category={item?.category}
                    categoryHref={item?.categoryHref}
                    collection={item?.collection}
                    title={item?.title}
                    date={item?.date}
                    tags={item?.tags}
                    tagBackground={item?.tagBackground}
                    tagTextColor={item?.tagTextColor}
                    href={item?.href}
                    {...(showVideoProgressBar && {
                      defaultValue: getVideoProgressInPercentage(
                        videosWatchTimes?.[item?.id],
                        item?.time
                      )
                    })}
                    isPremium={item?.isPremium}
                    instanceData={item?.instanceData}
                    instanceId={item?.instanceId}
                    instanceType={item?.instanceType}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

VideoCarousel.Loading = function Loading() {
  return <VideoCarouselLoading settings={settings} />;
};

export default VideoCarousel;
