import { Col, Row } from 'antd';
import CategoryGridLoading from '../../../skeleton/CategoryGridLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import CategoryCard from './Category';

function CategoryGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  data = []
}) {
  return (
    <section className="category-grid">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 16 },
            { xs: 8, sm: 8, md: 16, lg: 16 }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xxl={{ span: 6 }}
              >
                <CategoryCard
                  grid
                  href={item?.href}
                  topic={item?.topic}
                  backgroundColor={item?.backgroundColor}
                  textColor={item?.textColor}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

CategoryGrid.Loading = function Loading() {
  return <CategoryGridLoading />;
};

export default CategoryGrid;
