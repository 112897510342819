/* eslint-disable react/jsx-no-useless-fragment */
import { Modal } from 'antd';
import { XCircle } from 'phosphor-react';
import React from 'react';
import LoaderComponent from './LoaderComponent';

function ModalComponent(props) {
  const {
    centered = true,
    maskClosable = true,
    loading,
    children,
    width,
    ...rest
  } = props;

  return (
    <Modal
      closeIcon={<XCircle size={24} />}
      centered={centered}
      maskClosable={maskClosable}
      width={width}
      {...rest}
    >
      {loading ? <LoaderComponent setHeight={20} /> : <>{children}</>}
    </Modal>
  );
}

export default ModalComponent;
