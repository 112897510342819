import { Carousel } from 'antd';
import FeaturedCollectionCarouselLoading from '../../../skeleton/FeaturedCollectionCarouselLoading';
import FeaturedCollection from './FeaturedCollection';

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  draggable: true,
  loop: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        dots: true,
        arrows: false
      }
    }
  ]
};
function FeaturedCollectionCarousel({ data = [], showCta }) {
  return (
    <section className="featurecollection-slider">
      <Carousel {...settings}>
        {data?.map((item) => {
          return (
            <FeaturedCollection
              key={item?.id}
              img={item?.img}
              blurhash={item?.blurhash}
              title={item?.title}
              videoCount={item?.videoCount}
              description={item?.description}
              button={item?.button}
              href={item?.href}
              showCta={showCta}
            />
          );
        })}
      </Carousel>
    </section>
  );
}

FeaturedCollectionCarousel.Loading = function Loading() {
  return <FeaturedCollectionCarouselLoading settings={settings} />;
};

export default FeaturedCollectionCarousel;
